export const IMAGE_URL = 'https://img2.traum-ferienwohnungen.de/region/'

export const IMAGE_SIZES = {
    largeWide: 'grid-large-wide',
    large: 'grid-large',
    medium: 'grid-medium',
    small: 'grid-small',
}

export const USER_TYPE = {
    tourist: 'tourist',
    customer: 'customer',
}

export enum SITE_NAME {
    SRL_PAGE_LIST_VIEW = 'site_name_srl_page_list_view',
    SRL_PAGE_MAP_VIEW = 'karte',
    SRL_PAGE_TILE_VIEW = 'site_name_srl_page_tile_view',
    REGION_LANDING_PAGE = 'site_name_region_landing_page',
    BOOKING_KIT_PAGE = 'site_name_booking_kit_page',
}

export const PAGE_SYSTEM = 'is_guest_website_frontend'

export const WORLD_REGION_NODE_ID = 1
